<template>
	<div class="mappanelinfo d-flex" ref="mappanelinfo" style="overflow: hidden">
		<!-- Info Panels -->

		<div class="mappaneldetails overflow-y-auto overflow-x-hidden">
			<transition name="scroll-x-transition" mode="out-in" :duration="100">
				<component :is="panelType" :currentmap="currentmap" :idStopSelected="idStopSelected" />
			</transition>
		</div>

		<!-- Tabs -->
		<div class="mt-7 mappaneltabs d-flex flex-column fill-height" style="z-index: 1">
			<!-- Tab Settings -->
			<div
				v-if="selectAction == '' && !isPanelHide"
				class="tab tabsettings"
				:class="activeTabSettings"
				@click="showTabSettings"
				:title="$t('operations.layers.title')"
			>
				<v-icon v-show="isActiveTabSettings" small color="primary">fas fa-sliders-v</v-icon>
				<v-icon v-show="!isActiveTabSettings" small color="grey">fas fa-sliders-v</v-icon>
			</div>

			<!-- Tab Authorize -->
			<div
				v-if="showAuthorizePanel"
				class="tab tabrequesteds"
				:class="activeTabRequesteds"
				@click="showTabRequesteds"
				:title="$t('shipsituation.resquesteds.title')"
			>
				<v-icon v-show="isActiveTabRequesteds" small color="primary">fa-kit fa-line-select</v-icon>
				<v-icon v-show="!isActiveTabRequesteds" small color="grey">fa-kit fa-line-select</v-icon>
			</div>

			<!-- Tab Planning -->
			<div
				v-if="showPlanningPanel"
				class="tab tabplanning"
				:class="activeTabPlanning"
				@click="showTabPlanning"
				:title="$t('shipsituation.planned.title')"
			>
				<v-icon v-show="isActiveTabPlanning" small color="primary">fa-kit fa-line-calendar</v-icon>
				<v-icon v-show="!isActiveTabPlanning" small color="grey">fa-kit fa-line-calendar</v-icon>
			</div>

			<!-- Tab instant past -->
			<div
				v-if="showPastPanel"
				class="tab tabinstantpast"
				:class="activeTabInstantPast"
				@click="showTabInstantPast"
				:title="$t('shipsituation.pastpanel.title')"
			>
				<v-icon v-show="isActiveTabInstantPast" small color="primary">fa-kit fa-line-past</v-icon>
				<v-icon v-show="!isActiveTabInstantPast" small color="grey">fa-kit fa-line-past</v-icon>
			</div>

			<!-- Tab instant future -->
			<div
				v-if="showFuturePanel"
				class="tab tabinstantfuture"
				:class="activeTabInstantFuture"
				@click="showTabInstantFuture"
				:title="$t('shipsituation.futurepanel.title')"
			>
				<v-icon v-show="isActiveTabInstantFuture" small color="primary">fa-kit fa-line-future</v-icon>
				<v-icon v-show="!isActiveTabInstantFuture" small color="grey">fa-kit fa-line-future</v-icon>
			</div>

			<!-- Dynamic Tabs -->
			<dynamic-tabs :isPanelHide="isPanelHide" :currentmap="currentmap" />

			<!-- Tab hide panel -->
			<div class="tabhide" :class="activeTabHide" @click="actionHideTab" :title="$t('operations.layers.title')">
				<v-icon v-show="isActiveTabHide" small color="white">far fa-angle-double-right</v-icon>
				<v-icon v-show="!isActiveTabHide" small color="white">far fa-angle-double-left</v-icon>
			</div>
		</div>
	</div>
</template>

<script>
import SvgIcon from '@/components/icon/SvgIcon';
import { SETTINGS_PANEL_ID, INSTANT_PAST_PANEL_ID, INSTANT_FUTURE_PANEL_ID, REQUESTEDS_PANEL_ID, PLANNING_PANEL_ID } from '@/lib/variables/panels';
import DynamicTabs from '@/components/operations/map/commonpanel/dynamics/DynamicTabs';

const SettingsMapPanel = () => import('@/components/operations/map/sections/infoais/settings/SettingsMapPanel');
const InstantPastMapPanel = () => import('@/components/operations/map/sections/infoperiods/instantpast/InstantPastMapPanel');
const InstantFutureMapPanel = () => import('@/components/operations/map/sections/infoperiods/instantfuture/InstantFutureMapPanel');
const RequestedsMapPanel = () => import('@/components/operations/map/sections/infoperiods/requesteds/RequestedsMapPanel');
const PlanningMapPanel = () => import('@/components/operations/map/sections/infoperiods/planning/PlanningMapPanel');

const DynamicPanels = () => import('@/components/operations/map/commonpanel/dynamics/DynamicPanels');

export default {
	name: 'mappanelinfoperiods',

	props: {
		currentmap: {
			type: String,
			required: true
		},

		idStopSelected: {
			type: String,
			default: null,
			required: false
		},

		selectAction: {
			type: String,
			required: false
		}
	},

	created() {
		this.$store.commit('setUserQuaysId', this.$store.getters.getUserBerths);
		if (this.authorizeFunc) {
			this.showTabRequesteds();
		} else {
			this.showTabInstantPast();
		}
	},

	data() {
		return {
			isPanelHide: false
		};
	},

	mounted() {},
	destroyed() {},
	components: {
		SvgIcon,
		SettingsMapPanel,
		InstantPastMapPanel,
		InstantFutureMapPanel,
		RequestedsMapPanel,
		PlanningMapPanel,
		DynamicPanels,
		DynamicTabs
	},

	computed: {
		panelType() {
			const map = {
				[SETTINGS_PANEL_ID]: 'settings-map-panel',
				[INSTANT_PAST_PANEL_ID]: 'instant-past-map-panel',
				[INSTANT_FUTURE_PANEL_ID]: 'instant-future-map-panel',
				[REQUESTEDS_PANEL_ID]: 'requesteds-map-panel',
				[PLANNING_PANEL_ID]: 'planning-map-panel'
			};

			return map[this.activePanel] || 'dynamic-panels';
		},

		activePanel() {
			return this.$store.getters.activePanelPeriods;
		},

		isActiveTabSettings() {
			return this.$store.getters.activePanelPeriods === SETTINGS_PANEL_ID;
		},

		activeTabSettings() {
			return {
				tabactive: this.isActiveTabSettings
			};
		},

		isActiveTabInstantPast() {
			return this.$store.getters.activePanelPeriods === INSTANT_PAST_PANEL_ID;
		},

		activeTabInstantPast() {
			return {
				tabactive: this.isActiveTabInstantPast
			};
		},

		isActiveTabInstantFuture() {
			return this.$store.getters.activePanelPeriods === INSTANT_FUTURE_PANEL_ID;
		},

		activeTabInstantFuture() {
			return {
				tabactive: this.isActiveTabInstantFuture
			};
		},

		isActiveTabRequesteds() {
			return this.$store.getters.activePanelPeriods === REQUESTEDS_PANEL_ID;
		},
		isActiveTabPlanning() {
			return this.$store.getters.activePanelPeriods === PLANNING_PANEL_ID;
		},
		activeTabRequesteds() {
			return {
				tabactive: this.isActiveTabRequesteds
			};
		},
		activeTabPlanning() {
			return {
				tabactive: this.isActiveTabPlanning
			};
		},
		authorizeFunc() {
			return this.$store.getters.getSession.functionalities.includes('ACTION_AUTHORIZE_STOP');
		},
		planningFunc() {
			return this.$store.getters.getSession.functionalities.includes('ACTION_PLAN_STOP');
		},
		showPastPanel() {
			return this.selectAction == '' && !this.isPanelHide && !this.dynamicTabsAreOpenedNotPast;
		},
		showFuturePanel() {
			return this.selectAction == '' && !this.isPanelHide && !this.dynamicTabsAreOpenedNotFuture;
		},
		showPlanningPanel() {
			if (
				!this.isPanelHide &&
				this.planningFunc &&
				(this.selectAction == '' || this.selectAction == 'planningselect') &&
				!this.dynamicTabsAreOpenedNotPlanning
			) {
				return true;
			}
			return false;
		},
		showAuthorizePanel() {
			if (
				!this.isPanelHide &&
				this.authorizeFunc &&
				(this.selectAction == '' || this.selectAction == 'authorizeselect') &&
				!this.dynamicTabsAreOpenedNotRequesteds
			) {
				return true;
			}
			return false;
		},
		isActiveTabHide() {
			return this.isPanelHide;
		},

		activeTabHide() {
			return {
				tabactive: this.isPanelHide
			};
		},
		dynamicTabsAreOpenedNotPast() {
			return this.$store.getters.dynamicPanelsPeriods.filter((panel) => panel.instantPanel != INSTANT_PAST_PANEL_ID).length > 0;
		},
		dynamicTabsAreOpenedNotFuture() {
			return this.$store.getters.dynamicPanelsPeriods.filter((panel) => panel.instantPanel != INSTANT_FUTURE_PANEL_ID).length > 0;
		},
		dynamicTabsAreOpenedNotPlanning() {
			return this.$store.getters.dynamicPanelsPeriods.filter((panel) => panel.instantPanel != PLANNING_PANEL_ID).length > 0;
		},
		dynamicTabsAreOpenedNotRequesteds() {
			return this.$store.getters.dynamicPanelsPeriods.filter((panel) => panel.instantPanel != REQUESTEDS_PANEL_ID).length > 0;
		}
	},

	methods: {
		actionHideTab() {
			this.isPanelHide = !this.isPanelHide;
			this.$emit('isHidePanel', this.isPanelHide);
		},

		showTabSettings() {
			this.showTab(SETTINGS_PANEL_ID);
		},

		showTabInstantPast() {
			this.showTab(INSTANT_PAST_PANEL_ID);
		},

		showTabInstantFuture() {
			this.showTab(INSTANT_FUTURE_PANEL_ID);
		},

		showTabRequesteds() {
			this.showTab(REQUESTEDS_PANEL_ID);
		},
		showTabPlanning() {
			this.showTab(PLANNING_PANEL_ID);
		},

		showTab(panelId) {
			this.$store.commit('setActivePanelPeriods', { panelId, instantPanel: this.activePanel });
			this.$store.commit('setPanelParentActive', { panelId });
		}
	}
};
</script>

<style lang="postcss">
@import '../../../../../styles/app-variables.pcss';
.mappanelinfo {
	width: 100%;
	height: 100%;
}
.mappaneldetails {
	position: relative;
	width: calc(100% - 42px);
	background-color: var(--very-low);
}
.mappaneltabs {
	background-color: transparent;
	width: 42px;
	height: 100vh;
	float: right;

	& .tab {
		width: 40px;
		height: 40px;
		background-color: var(--low);
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
		border-bottom: 1px solid #bbb;
		margin-bottom: 3px;
		font-size: 16px;
		color: var(--primary);
		&.tabinstantpast {
			i {
				padding-top: 12px;
				padding-left: 12px;
			}
		}

		&.tabinstantfuture {
			i {
				padding-top: 12px;
				padding-left: 12px;
			}
		}
		&.tabactive {
			background-color: var(--very-low);
			i {
				color: var(--eiffel-accent);
			}
		}
		&:hover {
			cursor: pointer;
		}
	}
}
.tabhide {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 60px;
	background-color: var(--primary);
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
	border-bottom: 1px solid #bbb;
	margin-bottom: 45px;
	font-size: 16px;
	color: var(--primary);
	float: inline-end;
}
</style>
